import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Avatar, FormLabel, Menu, MenuItem } from "@mui/material";
//import HomeIcon from '@mui/icons-material/Home'
import AvatarWithBorder from "../AvatarWithBorder";

import { useMainLayoutContext } from "../../../contexts/mainLayoutState/MainLayoutProvider";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  avatarRoot: {
    margin: "0 0.5rem 0 0",
    border: `0px solid pink`,
    transition: `border 1s linear`
  },
  avatarRootDriverOffline: {
    border: `2px dashed red`,
    transition: `border 1s linear`
  },
  avatarRootDriverBusy: {
    border: `2px solid green`,
    transition: `border 1s linear`
  },
  avatarRootDriverAvailable: {
    border: `2px solid green`,
    transition: `border 1s linear`
  },
  avatarRootDriverRefractory: {
    border: `2px solid yellow`,
    transition: `border 1s linear`
  },
  avatarRootDriverError: {
    border: `2px solid red`,
    transition: `border 1s linear`
  },
  avatarImg: {
    transition: `none`
  },
  row: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  text: {
    fontWeight: 500,
    color: theme.palette.background.default
  },
  menu: {
    top: "4rem !important"
  },
  menuItem: {
    fontWeight: 400,
    color: theme.palette.text.primary
  }
}));

export default ({ anchorEl, setAnchorEl }) => {
  const mainLayoutCtx = useMainLayoutContext();
  const {
    setSelectedSonityProfile,
    setCampaignDefinitions
  } = mainLayoutCtx.stateFunctions;
  const { sonityProfiles, drivers } = mainLayoutCtx.state;
  const classes = useStyles();

  const _handleMenuItemClick = (e, index) => {
    console.log("clicked ", index);
    console.log(sonityProfiles[index]);
    setSelectedSonityProfile(sonityProfiles[index]);
    setCampaignDefinitions([]);
    setAnchorEl(null);
  };
  const _handleClose = e => {
    setAnchorEl(null);
  };

  return (
    <div id="profile-dropdown" className={classes.root}>
      <Menu
        id="profile-menu"
        classes={{ paper: classes.menu }}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={_handleClose}
      >
        {sonityProfiles &&
          sonityProfiles.map((sp, index) => (
              <MenuItem
                classes={{ root: classes.menuItem }}
                key={`sp-${sp.id}`}
                onClick={event => _handleMenuItemClick(event, index)}
              >
                <AvatarWithBorder
                  driver={
                    drivers
                      ? drivers.find(
                          d => parseInt(d.sonity_profile_id) === parseInt(sp.id)
                        )
                      : null
                  }
                  sonity_profile={sp}
                />
                <FormLabel>{sp.email}</FormLabel>
                {sp.name}
              </MenuItem>
            ))}
      </Menu>
    </div>
  );
};
