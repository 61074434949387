import { useCallback, useEffect, useState } from "react";
import { feathersClient } from "../attach-client";


/**
 * @name useDrivers
 * @type component
 * @desc Is a hook that allows you to interact with a user's drivers. A user's profile will execute tasks on these drivers.
 * @prop {Object} user - Current user
 * @prop {Function} updateNotification  - function for showing snackbar (updateMessage)
 * @prop {Object} state - An object of  MainLayout current state
 * @returns An object with driver data and functions
 */
export const useDrivers = (user, state, updateNotification) => {
  const { drivers, selectedSonityProfile, selectedSonityAccount } = state;
  const [associatedDriver, setAssociatedDriver] = useState(null);
  const [loading, setLoading] = useState(false);

  const SELENIUM_ENDPOINT = selectedSonityAccount?.selenium_endpoint;


  /**
 * @name handleRefreshDriverTasks
 * @type function
 * @desc Jump start a driver. On successful execution a driver should begin executing tasks
 * @param {Object} selectedSonityProfile - This function is cached so a new version is generated each time the selectedSonityProfile changes
 * @returns It displays a message on the snackbar
 */
  const handleRefreshDriverTasks = useCallback(() => {

    if (!SELENIUM_ENDPOINT) return;
    
    setLoading(true);
    return fetch(
      `${SELENIUM_ENDPOINT}/refresh-tasks/${selectedSonityProfile.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization:
            feathersClient.authentication.options.storage["feathers-jwt"],
          "x-sonity-user-id": user.id,
          "x-sonity-account-id": selectedSonityAccount.id
        }
      }
    )
      .then(r => r.json())
      .then(obj => {

        if (updateNotification)
          updateNotification({
            isShown: true,
            variant: "success",
            content: "The driver has started its task queue. \n\n"
          });
      })
      .catch(e => {
        setLoading(false);
        if (updateNotification)
          updateNotification({
            isShown: true,
            variant: "error",
            content:
              "There was a problem forcing the driver into its' task queue. \n\n" +
              e.toString()
          });
      });
  }, [selectedSonityProfile]);



 /**
  * @name fetchSnapshot
  * @type function
  * @desc Fetch snapshot of the driver
  * @param {int} sonity_profile_id 
  * @returns A snapshot
  */
  const fetchSnapshot = async (sonity_profile_id = null) => {
      try {
        const url = sonity_profile_id ? `${selectedSonityAccount.selenium_endpoint}/take-snapshot/${sonity_profile_id}` : `${selectedSonityAccount.selenium_endpoint}/take-snapshot/${selectedSonityProfile.id}`
        const res = await fetch(
          url,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization:
                feathersClient.authentication.options.storage["feathers-jwt"],
              "x-sonity-user-id": user.id,
              "x-sonity-account-id": selectedSonityAccount.id,
              "x-sonity-profile-id": sonity_profile_id ? sonity_profile_id : selectedSonityProfile.id
            },
            body: JSON.stringify({
              sonity_profile_id: sonity_profile_id ? sonity_profile_id : selectedSonityProfile.id
            })
          }
        )
      const ss = await res.json();
      return ss;
      } catch(e) {
        throw e;
      }
    
  };

   /**
 * @name selectedSonityProfile effect
 * @type function
 * @desc Is a hook that that sets the currect selected profile's associated driver
 * @returns null
 */
  useEffect(() => {
    if (selectedSonityProfile) {
      const ad = drivers.find(
        d => d.sonity_profile_id == selectedSonityProfile.id
      );

      
      if (ad && (!associatedDriver || associatedDriver.id !== ad.id)) {
        setAssociatedDriver(ad);
      }
      if (!ad) {
        setAssociatedDriver(null);
      }
    }
  }, [selectedSonityProfile]);

  return [
    {
      associatedDriver,
      drivers,
      loading
    },
    {
      handleRefreshDriverTasks,
      fetchSnapshot
    }
  ];
};
