import { useEffect, useState } from "react";

import { EVENT_CREATED } from "../gql/subscriptions/Events";
import { useSubscription } from "@apollo/client";
import { handleBrowserNotifications } from "../utils/browserNotifications";
import { getGqlClient, refreshGqlClient } from "../attach-client";


/**
 * @name useEventSubs
 * @type component
 * @desc Is a hook that allows you subscribe to sonity events and get data from those events when recieved
 * @prop {Object} user - Current user
 * @prop {Object} mainLayoutState - An object of  MainLayout current state
 * @prop {Object} mainLayoutStateFunctions  - An object of Main Layout state functions
 * @returns An object event data and functions
 */

const useEventSubs = (user, mainLayoutState, mainLayoutStateFunctions) => {
  const [sessionMessages, setSessionMessages] = useState([]);
  const { sonityProfiles } = mainLayoutState;
  const {
    triggerVerificationModals,
    killDriver,
    updateDriver,
    createdDriver
  } = mainLayoutStateFunctions;

  const { data: eventsSubData, loading: eventsSubLoading, error: eventsSubError , subscribeToMore} = useSubscription(EVENT_CREATED, {
    variables: {
      user_id: user ? user.id : null
    },
    client: getGqlClient(),
    // this will fire each time message is send via linkedin
    onSubscriptionData: ({ subscriptionData }) => {
      console.log("Event Created: ", subscriptionData);
      const { eventCreated } = subscriptionData.data;

      let eventType = eventCreated.type
      let eventPayload = JSON.parse(eventCreated.payload)


      try {
        // Attempt to parse the payload as JSON
        const jsonPayload = JSON.parse(eventCreated.payload);
        eventPayload =  jsonPayload;
      } catch (error) {
        eventPayload = eventPayload.payload;
      }
        

      mainLayoutStateFunctions.setEventSubs({
        type: eventType,
        payload: eventPayload
      })

      // Event handlers
      if (handleBrowserNotifications)
        handleBrowserNotifications(eventCreated, sonityProfiles);
      if (triggerVerificationModals) triggerVerificationModals(eventCreated);

      if (eventCreated.type == "DRIVER_CREATED" && createdDriver)
        createdDriver(eventCreated);
      if (eventCreated.type == "DRIVER_UPDATED" && updateDriver)
        updateDriver(eventCreated);
      if (eventCreated.type == "DRIVER_KILLED" && killDriver)
        killDriver(eventCreated);

      
    }
  });
  
  return {
    sessionMessages,
    eventsSubData,
    eventsSubLoading,
    eventsSubError
  };
};

export default useEventSubs;
