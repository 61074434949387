import gql from "graphql-tag";

export const UPDATE_PREFERENCE = gql`
  mutation updatePreference($input: SonityPreferencesInput!) {
    update_sonity_preference(input: $input) {
      id
      sonity_profile_id
      type
      preferences
      extensions
      time_zone
      work_on_weekends
      start_time
      end_time
      extract_mode
      thread_sync_mode
      revoke_mode
      pause_time
      scan_time
      auto_launch
    }
  }
`;
