import ActionType from "./ActionTypes";

const HomeReducer = (state, action) => {
  const { type, payload, source } = action;

  console.log("Current State: ", state);
  console.log("Current Action: ", action);

  switch (type) {
    case ActionType.set_item_to_delete:
      return {
        ...state,
        itemToDelete: payload
      };
    case ActionType.set_task_state:
      return {
        ...state,
        tasksState: payload
      };
    case ActionType.toggle_confirmation_dialog:
      return {
        ...state,
        confirmationDialogOpen: !state.confirmationDialogOpen
      };
    case ActionType.toggle_notifications_dialog:
      return {
        ...state,
        notificationsDialogOpen: !state.notificationsDialogOpen
      };
    case ActionType.set_selected_task_table:
      return {
        ...state,
        selectedTaskType: payload
      };
    case ActionType.set_text_search:
      return {
        ...state,
        textSearch: payload
      };
    case ActionType.set_task_state:
      return {
        ...state,
        tasksState: payload
      };
    case ActionType.toggle_extract_contacts_dialog:
      return {
        ...state,
        isExtractContactsDialogOpen: !state.isExtractContactsDialogOpen
      };
    case ActionType.toggle_driver_dialog:
      return {
        ...state,
        driverDialogOpen: !state.driverDialogOpen
      };
    case ActionType.set_state:
      console.log("New State Set State: ", {
        ...state,
        ...payload
      });
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};

export default HomeReducer;
