import { useMutation } from "@apollo/client";
import { UPDATE_PREFERENCE } from "../gql/mutations/SonityPreferences";
import { useMainLayoutContext } from "../contexts/mainLayoutState/MainLayoutProvider";

export const useSonityPreferences = ( ) => {
  const mainLayoutCtx = useMainLayoutContext();
  const { sonityProfiles } = mainLayoutCtx.state;
  const [message, { updateMessage, hideMessage }] = mainLayoutCtx.useMessageHook;
  const {
    updateSonityProfilePreferences,
    setSelectedSonityProfile
  } = mainLayoutCtx.stateFunctions;

  const [updatePreference, updatePreferenceResult] = useMutation(
    UPDATE_PREFERENCE,
    {
      //refetchQueries: ["getSonityProfiles"],
      onCompleted: data => {
        if (data && data.update_sonity_preference) {
          const new_pref = data.update_sonity_preference;
          console.log("UPDATED PREF:", new_pref);
          updateSonityProfilePreferences(data.update_sonity_preference);

          updateMessage({
            isShown: true,
            variant: "success",
            content: "Sonity preferences updated successfully."
          });
        }
      },
      onError: e => {
        console.log(e);
      }
    }
  );

  return [
    {
      updatePreference
    },
    {
      updatePreferenceResult
    }
  ];
};
